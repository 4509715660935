<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="parkingType">
          <el-select v-model="query.parkingLotType" clearable placeholder="请选择-车场类型">
            <el-option label="路内车场" value="1"></el-option>
            <el-option label="路外车场" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="parkingStatus">
          <el-select v-model="query.status" clearable placeholder="请选择-车场状态">
            <el-option label="正常" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="name">
          <el-input v-model="query.name" clearable placeholder="车场名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="doReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-button type="primary" size="small" @click="toAdd">新增</el-button>
      </div>
      <el-table v-loading="isLoading" :data="dataList" border class="common-table" size="medium" style="width: 100%">
        <el-table-column label="车场名称" prop="name" fixed min-width="200">
        </el-table-column>
        <el-table-column label="车场ID" prop="id" width="160">
        </el-table-column>
        <el-table-column label="车场编号" prop="code" width="160">
        </el-table-column>
        <!--          <el-table-column label="城区" prop="regionName" width="180">-->
        <!--          </el-table-column>-->
        <el-table-column label="车场类型" prop="parkingLotType" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.parkingLotType === 1">路内车场</span>
            <span v-if="scope.row.parkingLotType === 0">路外车场</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="deployMode" label="部署模式" width="180">
        </el-table-column> -->
        <el-table-column label="注册时间" prop="createDate" width="140">
        </el-table-column>
        <el-table-column label="更新时间" prop="updateTime" width="140">
        </el-table-column>
        <el-table-column label="泊位数量" prop="spaceTotalCount" width="80">
        </el-table-column>
        <el-table-column label="车场状态" prop="status" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">正常</span>
            <span v-if="scope.row.status === 1">禁用</span>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" prop="name" width="220px">
          <template slot-scope="scope">
            <el-button size="small common-text" type="text" @click="toDetail(scope.row)">详情
            </el-button>
            <el-button size="small common-text" type="text" @click="doEdit(scope.row)">编辑
            </el-button>
            <el-button v-if="scope.row.parkingLotType == '1'" size="small common-text" type="text"
              @click="doEditParkingSpace(scope.row)">泊位管理</el-button>
            <el-button v-if="scope.row.parkingLotType == '0'" size="small common-text" type="text"
              @click="doEditParkingGate(scope.row)">通道配置</el-button>
            <el-button size="small common-text" type="text" @click="showQrCodeDialog(scope.row)">场内码</el-button>
            <el-button size="small common-text" type="text" @click="showCarCodeDialog(scope.row)">车道码</el-button>
            <el-button style="color: #EA5504!important;" v-if="scope.row.status === 0" size="small common-text"
              type="text" @click="updateParkingLotStatus(scope.row.id, '1')">禁用
            </el-button>
            <el-button v-if="scope.row.status === 1" size="small common-text" type="text"
              @click="updateParkingLotStatus(scope.row.id, '0')">启用
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize" :total="pagination.totalSize"
          background layout="sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>

    <el-dialog :append-to-body="true" :before-close="qrCodeDialogBeforeClosed" :visible.sync="qrCodeDialogVisible"
      class="common-dialog" title="下载二维码" width="240px">
      <div>
        <QrCode :id="'QrCode'" :codeUrl="curQrCode" />
      </div>
    </el-dialog>

    <el-dialog :append-to-body="true" :before-close="qrCodeDialogBeforeClosed" :visible.sync="carCodeDialogVisible"
      class="common-dialog" title="车道码" width="650px">
      <div>
        <el-table :data="carCodeList" border class="common-table" size="medium" style="width: 100%">
          <el-table-column label="停车场名称" prop="parkingLotName" min-width="200">
          </el-table-column>
          <el-table-column label="出口名称" prop="name" min-width="200">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="name" width="200px">
            <template slot-scope="scope">
              <el-button size="small common-text" type="text" @click="downloadQrcode(scope.row)">下载车道码
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <a ref="locatorQRCodeDownloadLinkRef" style="display: none"></a> -->
        <div ref="canvasCode" id="canvasCode" style="display: none;"></div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="passageDialogVisible" append-to-body class="common-dialog" title="通道配置" width="900px">
      <Passage ref="passage" @success="onSuccess" />
      <!-- :entryNumber="currentRow.entryNumber"
        :outNumber="currentRow.exportNumber" -->
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog :append-to-body="true" :visible.sync="exportDialogFormVisible" class="common-dialog" title="导出选项"
      width="600px">
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input v-model="form.exportSize" placeholder="请填写导出条数"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getParkingList,
  updateParkingLotStatus,
} from "@/api/operation";
import { getRegionList } from "@/api/user";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
// import Settlement from "@/components/Settlement";
import Passage from "./components/passage.vue";
import { doExport } from "@/api/common";
import {
  checkToken,
} from "@/api/common";
import { MessageBox } from "element-ui";
import QrCode from "@/components/QrCode.vue";
import {
  getParkingQrCode,
  searchParkingLotGateByParamPage
} from "@/api/entranceMonitoring";
import QRCode from 'qrcodejs2';
export default {
  name: "parking",
  mixins: [BaseMixin, Permission],
  components: {
    QrCode,
    // Settlement,
    Passage,
  },
  data() {
    return {
      curQrCode: '',
      qrCodeDialogVisible: false, //控制二维码弹窗
      carCodeDialogVisible: false,
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level, value } = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({ regionId: regionId })
            .then(({ returnObject }) => {
              console.log('getRegionList:', returnObject)
              const nodes = returnObject.map(item => ({
                value: item.id,
                label: item.name,
                leaf: item.level == 3,
              })
              )
              resolve(nodes)
            })
        }
      },
      query: {
        // 停车场类型
        parkingLotType: "",
        // 停车场状态
        status: "",
        // 结算方ID
        settlementId: "",
        // 区划
        regionId: "530129",
        // 停车场名称
        name: "",
        // 0-运营 1-机关事业单位 2-联网
        managementCompany: "", //管理公司名称
        onlineParkingOrder: "", //运营模式 1运营 2仅数据
        motionType: 0,
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      dataList: [],
      nowStatus: "",
      passageDialogVisible: false,
      currentRow: {},
      carCodeList: [],
    };
  },
  mounted() {
  },
  activated() {
    // const isSave = window.sessionStorage.getItem('save_success');
    const isSave = sessionStorage.getItem("save_parkinglot_success");
    if (isSave === "2") {
      // 刷新
      this.doSearch();
    }
  },
  methods: {
    //显示二维码
    showQrCodeDialog(item) {
      getParkingQrCode({
        parkingLotId: item.id,
        qrCodeType: 1,
      }).then((res) => {
        if (res && res.code === 30 && res.result) {
          console.log(res)
          this.curQrCode = res.returnObject
          this.qrCodeDialogVisible = true
        }
      });
    },
    showCarCodeDialog(item) {
      searchParkingLotGateByParamPage({
        parkingLotId: item.id,
        pageNo: 1,
        pageSize: 50
      }).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.carCodeList = res.returnObject.records;
          this.carCodeDialogVisible = true
        }
      });
    },

    downloadQrcode(item) {//下载
      getParkingQrCode({
        parkingLotId: item.parkingLotId,
        parkingLotGateId: item.id,
        qrCodeType: 0,
      }).then((res) => {
        if (res && res.code === 30 && res.result) {
          // this.createQrcode();
          this.createQrcode(res.returnObject);
          console.log(this.$refs['canvasCode'].getElementsByTagName?.('canvas'));
          
          let qrcodeCanvas = ((this.$refs['canvasCode'] || {})?.getElementsByTagName?.('canvas') || [])?.[0];
          let qrcodeImgUrl = qrcodeCanvas?.toDataURL?.('image/png'); // 作为下载图片资源
          console.log(qrcodeImgUrl);

          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', qrcodeImgUrl);
          downloadLink.setAttribute('download', `二维码_${new Date().getTime()}.png`);
          // 触发下载  
          document.body.appendChild(downloadLink);
          downloadLink.click();
          // 清理：从 DOM 中移除 a 标签  
          document.body.removeChild(downloadLink);
        }
      });
      // let downloadLink = this.$refs.locatorQRCodeDownloadLinkRef;
      // let qrcodeCanvas = ((this.$refs[item.id] || {})?.getElementsByTagName?.('canvas') || [])?.[0];
      // let qrcodeImgUrl = qrcodeCanvas?.toDataURL?.('image/png'); // 作为下载图片资源
      // downloadLink.setAttribute('href', qrcodeImgUrl);
      // downloadLink.setAttribute(
      //   'download',
      //   `二维码_${new Date().getTime()}.png`
      // );
      // downloadLink.click();
      // URL.revokeObjectURL(downloadLink.href);
    },

    createQrcode(codeUrl) {
      console.log(codeUrl);
      
      // 使用 QRCode 库生成二维码到 canvas  
      // const qrcodeCanvas = document.createElement('canvas');  
      // // 设置 canvas 的大小  
      // qrcodeCanvas.width = '200';  
      // qrcodeCanvas.height = '200'; 

      new QRCode(this.$refs['canvasCode'], {
        text: codeUrl,
        width: '200',
        height: '200',
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
      // 返回 canvas 的图片 URL  
      // return qrcodeCanvas.toDataURL('image/png');  
    },
    //关闭完结弹窗清空表单
    qrCodeDialogBeforeClosed(done) {
      this.doReset()
      done();
    },
    showPassage(row) {
      this.passageDialogVisible = true;
      this.currentRow = row;

      this.$nextTick(() => {
        this.$refs.passage.parkingId = row.id;
        // this.$refs.passage.getNumber();
        // this.$refs.passage.getGangtingDeviceList();
        this.$refs.passage.getParkingPassageList();
      });
    },
    onSuccess(e) {
      this.passageDialogVisible = e;
    },
    async doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      checkToken()
        .then(async res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            this.isLoading = true;
            this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
            const res = await getParkingList(this.paramFormat(this.query));
            this.isLoading = false;

            if (res && res.code === 30 && res.result) {
              const returnObject = res.returnObject;
              this.dataList = returnObject.records;
              this.pagination.totalSize = returnObject.total;
              this.pagination.totalPages = returnObject.pages;
              this.form.exportSize = returnObject.total;
            }
          }
        })
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport(
        "/parkingLotManagementController/findParkingLotInfo",
        params,
        "停车场列表.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    updateParkingLotStatus(parkingId, status) {
      this.isLoading = true;
      if (status === '0') {
        this.nowStatus = "启用";
      } else if (status === '1') {
        this.nowStatus = "禁用";
      }
      this.$confirm(`此操作将${this.nowStatus}车场, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateParkingLotStatus({
            id: parkingId,
            type: status,
          }).then((res) => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("操作成功！");
              this.doSearch();
            }
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    doReset() {
      this.query = {
        // 停车场类型
        type: "",
        // 停车场状态
        status: "",
        // 结算方ID
        settlementId: "",
        // 区划
        regionId: "530129",
        // 停车场名称
        parkingLotName: "",
        // 0-运营 1-机关事业单位 2-联网
        managementCompany: "", //管理公司名称
        onlineParkingOrder: "", //运营模式 1运营 2仅数据
        motionType: 0,
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      };
      this.doSearch();
    },
    toAdd() {
      this.$router.push({
        path: "/parking_manage/add",
        query: {
          motionType: this.query.motionType,
        },
      });
    },
    doEdit(item) {
      this.$router.push({
        path: "/parking_manage/add",
        query: {
          parkingId: item.id,
          parkingName: item.name,
          motionType: this.query.motionType,
        },
      });
    },
    doEditParkingSpace(item) {
      this.$router.push({
        path: "/parking_manage/edit_parkingspace",
        query: {
          parkingId: item.id,
          parkingName: item.name,
          motionType: this.query.motionType,
        },
      });
    },
    doEditParkingGate(item) {
      this.$router.push({
        path: "/parking_manage/edit_parkingGate",
        query: {
          parkingId: item.id,
          parkingName: item.name,
          motionType: this.query.motionType,
        },
      });
    },
    toDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/parking_manage/detail",
        query: {
          parkingId: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
